import React from "react";

import { companyValues } from "./constants";
import CardSections from "./CardSection";

const CompanyValues = ({ id, ref, activated }) => {
  return (
    <CardSections
      sectionTitle='Values'
      data={companyValues}
      id={id}
      activated={activated}
    />
  );
};

export default CompanyValues;
