import React from "react";
import { Heading, HStack } from "@chakra-ui/react";
import { Link } from "react-scroll";

import { CompanyLogo } from "../Logo";

const NavLogo = () => (
  <HStack alignItems='center' cursor='pointer'>
    <Link to='home' smooth={true}>
      <CompanyLogo
        extraStyles={{ display: ["none", "none", "block", "block", , "block"] }}
      />
    </Link>
    <Heading
      color='whiteAlpha.900'
      ml={4}
      size={["lg", "lg", "lg", "xl", "xl", "xl"]}>
      AUSK Engineering Solutions
    </Heading>
  </HStack>
);
export default NavLogo;
