import React from "react";
import { Heading, Box, VStack, Text } from "@chakra-ui/react";
import { Link } from "react-scroll";

import MainButton from "../../components/Buttons/MainButton";

const RightContent = ({ showAnimation }) => {
  return (
    <VStack
      justifyContent={[
        "center",
        "center",
        "center",
        "center",
        "flex-start",
        "flex-start",
      ]}
      top={[0, 0, 0, 0, "20%", "20%"]}
      position={[
        "relative",
        "relative",
        "relative",
        "relative",
        "absolute",
        "absolute",
      ]}
      alignItems={[
        "center",
        "center",
        "center",
        "center",
        "flex-start",
        "flex-start",
      ]}
      bgColor={[
        "#FBD38D",
        "#FBD38D",
        "#FBD38D",
        "#FBD38D",
        "transparent",
        "transparent",
      ]}
      width={["100%", "100%", "100%", "100%", "60%", "60%"]}
      px={[10, 10, 10, 10, 38, 38]}
      py={[20, 20, 20, 20, 0, 0]}
      opacity={showAnimation ? 1 : 0}
      transform={showAnimation ? "translateX(0%)" : "translateX(-100%)"} // Initially positioned off-screen to the left
      transition={[
        "opacity 0.5s ease-in-out",
        "opacity 0.5s ease-in-out",
        "opacity 0.5s ease-in-out",
        "opacity 0.5s ease-in-out",
        "transform 1s ease-out",
        "transform 1s ease-out",
      ]}
      zIndex={2}>
      <Box textAlign={["center", "center", "center", "center", "left", "left"]}>
        <Heading fontSize={[42, 42, 42, 42, 36, 42]} fontWeight='bold'>
          CIVIL & STRUCTURAL
        </Heading>
        <Heading fontSize={[42, 42, 42, 42, 36, 42]} fontWeight='bold'>
          ENGINEERING SOLUTIONS
        </Heading>
      </Box>
      <Box
        my={8}
        textAlign={["center", "center", "center", "center", "left", "left"]}
        width={["80%", "80%", "80%", "80%", "80%"]}>
        <Text fontSize={24} lineHeight='2rem' mb={4}>
          At <strong>AUSK</strong>, we provide consultation services outsourcing
          especially in <strong>C&S (Civil & Structure)</strong> for all clients
          around the world.
        </Text>
        <Text fontSize={24} lineHeight='2rem'>
          Our services include <strong>design and modeler processes </strong> to
          improve efficiency and reduce costs.
        </Text>
      </Box>
      <Box
        width='80%'
        textAlign={["center", "center", "center", "center", "right", "right"]}>
        <Link to='contact' smooth={true} duration={1000}>
          <MainButton text='Get in touch' onClick={() => {}} />
        </Link>
      </Box>
    </VStack>
  );
};

export default RightContent;
