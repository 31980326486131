import React from "react";
import { Divider, AbsoluteCenter, Box } from "@chakra-ui/react";
// Components
import { SectionInnerWrapper } from "../../components/Elements/SharedComponents";

import CompanyMissions from "./CompanyMissions";
import CompanyValues from "./CompanyValues";
import MeetOutTeams from "./MeetOurTeams";

import useScrollObserver from "../hooks/useScrollObserver";

const AboutUs = () => {
  const sectionIds = [
    "team-avatars-section",
    "values-section",
    "mission-section",
  ];
  const threshold = 0.2; // Example threshold value

  // Use the hook to track visible sections
  const visibleSections = useScrollObserver(sectionIds, threshold);

  return (
    <>
      <SectionInnerWrapper extraStyles={{ bgColor: "whiteAlpha.900" }}>
        <MeetOutTeams
          id='team-avatars-section'
          activated={visibleSections.includes("team-avatars-section")}
        />
        <Box mt={16}>
          <CompanyValues
            id='values-section'
            activated={visibleSections.includes("values-section")}
          />

          <CompanyMissions
            id='mission-section'
            activated={visibleSections.includes("mission-section")}
          />
        </Box>
      </SectionInnerWrapper>
    </>
  );
};

export default AboutUs;
