import React from "react";
import { HStack, Icon, Text } from "@chakra-ui/react";

const TextWithIcon = ({
  icon,
  text,
  iconColor,
  textColor,
  iconSize,
  extraWrapperStyles,
}) => (
  <HStack spacing={2} alignItems='center' {...extraWrapperStyles}>
    <Icon as={icon} color={iconColor} w={iconSize} h={iconSize} />
    <Text textColor={textColor} mb='1px'>
      {text}
    </Text>
  </HStack>
);
export default TextWithIcon;
