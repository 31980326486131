import {
  FaLinkedin,
  FaFacebook,
  FaAward,
  FaLightbulb,
  FaHandshake,
  FaRecycle,
} from "react-icons/fa";

import SampleAvatar1 from "../../assets/img/teams/kieran-tran.jpg";
import SampleAvatar2 from "../../assets/img/teams/sample.jpg";
import SampleAvatar3 from "../../assets/img/teams/thy-tran.jpeg";
import SampleAvatar4 from "../../assets/img/teams/kieran-tran.jpg";

export const boardTeamMembers = [
  //   {
  //     src: SampleAvatar1,
  //     name: "Khoa (Kieran) Tran",
  //     position: "CEO",
  //     linkedinUrl: "https://www.linkedin.com/in/khoatran2/",
  //   },
  // {
  //   src: SampleAvatar2,
  //   name: "Anh (Natalie) Hoang",
  //   linkedinUrl: "https://www.linkedin.com/in/hoang-anh-natalie-902b4738/",
  //   position: "CEO",
  // },
  // {
  //   src: SampleAvatar2,
  //   name: "Khoa Tran",
  //   linkedinUrl: "https://www.linkedin.com/in/khoatran2/",
  //   position: "Engineering Leader",
  // },
  //   { src: SampleAvatar4, name: "Nick Fury", position: "CMO" },
];

export const teamMembers = [
  {
    src: SampleAvatar2,
    name: "Anh (Natalie) Hoang",
    linkedinUrl: "https://www.linkedin.com/in/hoang-anh-natalie-902b4738/",
    position: "Co-Founder",
  },
  {
    src: SampleAvatar2,
    name: "Lynh Huynh",
    linkedinUrl: "",
    position: "Building Information Modeling Manager",
  },
  {
    src: SampleAvatar2,
    name: "Minh Thi",
    linkedinUrl: "",
    position: "Civil Engineer Manager",
  },
  {
    src: SampleAvatar2,
    name: "Thy Tran",
    // linkedinUrl: "https://www.linkedin.com/in/tranngocanhthy/",
    position: "Operations Manager",
  },
];

export const companyValues = [
  {
    id: 0,
    title: "Excellence",
    description:
      "Strive for excellence in every aspect of our work, delivering high-quality solutions that meet and exceed our clients' expectations.",
    icon: "excellence",
  },
  {
    id: 1,
    title: "Innovation",
    description:
      "Embrace innovation and constantly seek new ways to improve efficiency, reduce costs, and enhance the effectiveness of our services.",
    icon: "innovation",
  },
  {
    id: 2,
    title: "Integrity",
    description:
      "Conduct our business with the utmost integrity, maintaining honesty, transparency, and ethical standards in all our interactions.",
    icon: "integrity",
  },
];

export const companyMissions = [
  {
    id: 0,
    title: "Ensure Quality",
    description:
      "Ensure the highest level of quality in every project we undertake, adhering to industry standards and best practices to deliver durable, reliable, and sustainable solutions.",
    icon: "quality",
  },
  {
    id: 1,
    title: "Foster Growth",
    description:
      "Foster the continuous personal and professional growth and development of our team members, providing them with ample opportunities for learning, skill-building, and career advancement.",
    icon: "growth",
  },
  {
    id: 2,
    title: "Empower Clients",
    description:
      "Empower our clients with comprehensive consultation services, providing them with the knowledge and expertise needed to make informed decisions and achieve their project goals.",
    icon: "empower",
  },
];
