import React, { useState } from "react";
import styled from "styled-components";
import { formatDistanceToNow, format } from "date-fns";
import { get } from "lodash";

import {
  Box,
  Grid,
  Text,
  Link,
  Card,
  CardBody,
  Stack,
  Heading,
  Image,
  Divider,
  Button,
} from "@chakra-ui/react";

const RenderContent = ({ content }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  // Extract text from HTML content
  const textContent = get(content.match(/<[^>]*>([^<]*)<\/[^>]*>/g), "[0]", "");

  // Ensure content has a minimum length of 200 characters
  const trimmedContent =
    textContent.length > 200 ? textContent.slice(0, 150) + "..." : textContent;

  return (
    <div>
      <Text
        dangerouslySetInnerHTML={{
          __html: showFullContent ? content : trimmedContent,
        }}
        mb={4}
      />
      {textContent.length > 200 && (
        <Button size='sm' onClick={() => setShowFullContent((state) => !state)}>
          {showFullContent ? "Show Less" : "Show More"}
        </Button>
      )}
    </div>
  );
};

export default function BlogBox({
  tag,
  title,
  content,
  author,
  date,
  imageUrl,
  id,
}) {
  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - date;

    // If the difference is less than a week, show relative distance
    if (diffInMilliseconds < 7 * 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(date, { addSuffix: true });
    }

    // If over a week, show exact date and time
    return format(date, "MMMM dd, yyyy HH:mm:ss");
  };

  return (
    <Card maxW='sm'>
      <CardBody>
        <Image
          src={imageUrl}
          alt='Green double couch with wooden legs'
          borderRadius='lg'
        />
        <Stack mt='6' spacing='3'>
          <Link color='black' href={`/posts/${id}`} target='_blank'>
            <Heading size='md'>{title}</Heading>
          </Link>

          <RenderContent content={content} />
          <Text>
            By {author}, {formatDate(date)}
          </Text>
        </Stack>
      </CardBody>
      {/* <Divider /> */}
      {/* <CardFooter></CardFooter> */}
    </Card>
    // <WrapperBtn className='animate pointer'>
    //   <Wrapper className='whiteBg radius8 shadow'>
    //     <h3 className='font20 extraBold'>{title}</h3>
    //     <div dangerouslySetInnerHTML={{ __html: text }} />
    //     <p className='font13 extraBold'>{author}</p>
    //     <p>{formatDate(date)}</p>
    //     <div className='flex'>
    //       <p className='tag coralBg radius6 font13 extraBold'>{tag}</p>
    //     </div>
    //   </Wrapper>
    // </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
