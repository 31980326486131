import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 20px; /* Adjust the margin-bottom as needed */

  p,
  div {
    margin-bottom: 30px; /* Adjust the margin-bottom for paragraphs and divs inside the wrapper */
  }

  p > strong {
    font-size: 1.2em; /* Adjust the font-size for paragraphs inside strong tags */
  }

  ul {
    list-style-type: disc; /* Change disc to other values like circle or square if needed */
    margin-bottom: 10px; /* Adjust the margin-bottom for the ul */
  }

  li {
    margin-bottom: 8px; /* Adjust the margin-bottom for the li */
  }
`;

const PostPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  const fetchPostById = async (postId) => {
    try {
      const response = await fetch(
        `https://api.ausk.vn/wp-json/wp/v2/posts/${postId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch post");
      }
      const postData = await response.json();
      return postData;
    } catch (error) {
      console.error("Error fetching post:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      const postData = await fetchPostById(postId);
      setPost(postData);
    };
    fetchPost();
  }, [postId]);

  return (
    <div>
      <h1>Post Page</h1>
      {post ? (
        <Wrapper>
          <h2>{post.title.rendered}</h2>
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </Wrapper>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PostPage;
