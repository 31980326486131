import { Image, Box, Link } from "@chakra-ui/react";

import React from "react";
import logoSrc from "../../assets/img/logo/ausk-complete-logo.png";

export const CompanyLogo = ({ extraStyles }) => (
  <Image boxSize='80px' src={logoSrc} alt='AUSK' {...extraStyles} />
);

export const ClientLogo = ({ src, index, link, alt }) => (
  <Box
    key={index}
    w='100%'
    h='100px' // Set a consistent height for all logo frames
    cursor='pointer'
    display='flex'
    justifyContent='center'
    alignItems='center'
    _focusVisible={{
      outline: "none",
      border: "0px",
    }}>
    <Box
      as={Link}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      display='inline-block'
      w='150px' // Set a consistent width for all logo frames
      h='100%'
      overflow='hidden' // Ensure consistent overflow behavior
      border='1px solid #ddd' // Add a border for frame consistency
      borderRadius='lg' // Add rounded corners for a more modern look
      boxShadow='0 2px 5px rgba(0, 0, 0, 0.1)' // Add a subtle shadow for depth
      transition='box-shadow 0.2s' // Add transition for hover effect
      _hover={{
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Increase shadow on hover
      }}>
      <Image
        src={src}
        alt={alt}
        fallbackSrc='https://via.placeholder.com/150'
        boxSize='100%' // Fill the entire box
        objectFit='contain'
        px={4}
        _hover={{
          transform: "scale(1.2)",
        }}
      />
    </Box>
  </Box>
);
