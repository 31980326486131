import { trim, isEmpty } from "lodash";

const isRequired = (value) => {
  return isEmpty(value) ? "This field is required" : undefined;
};

const minLength = (value, length) => {
  return trim(value).length < length
    ? `Minimum length is ${length} characters`
    : undefined;
};
const emailPattern = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value)
    ? undefined
    : "Please enter a valid email address";
};

export { isRequired, minLength, emailPattern };
