import React from "react";

import { companyMissions } from "./constants";
import CardSections from "./CardSection";

const CompanyMissions = ({ id, ref, activated }) => {
  return (
    <CardSections
      id={id}
      activated={activated}
      sectionTitle='Missions'
      data={companyMissions}
    />
  );
};

export default CompanyMissions;
