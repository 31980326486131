import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { get } from "lodash";

import {
  Button,
  Heading,
  Box,
  Image,
  VStack,
  Text,
  Icon,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  SimpleGrid,
} from "@chakra-ui/react";

// Components
import BlogBox from "../components/Elements/BlogBox";

// Define API endpoint
const API_ENDPOINT =
  "https://api.ausk.vn/wp-json/wp/v2/posts?page=${page}&per_page=${perPage}";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPosts = async ({ page, perPage }) => {
    try {
      const response = await fetch(
        `https://api.ausk.vn/wp-json/wp/v2/posts?page=${page}&per_page=${perPage}`,
        {
          method: "GET",
          // Additional options like headers can be specified here if needed
        }
      );

      const posts = await response.json();

      // Fetch additional data for each post (author name and featured media)
      const postsWithData = await Promise.all(
        posts.map(async (post) => {
          const authorId = get(post, "author");
          const featuredMediaId = get(post, "featured_media");

          // Fetch author data
          const authorResponse = await fetch(
            `https://api.ausk.vn/wp-json/wp/v2/users/${authorId}`
          );
          const authorData = await authorResponse.json();
          const authorName = get(authorData, "name");

          // Fetch featured media data
          const featuredMediaResponse = await fetch(
            `https://api.ausk.vn/wp-json/wp/v2/media/${featuredMediaId}`
          );
          const featuredMediaData = await featuredMediaResponse.json();
          const imageUrl = get(featuredMediaData, "source_url");

          // Extract desired fields from post data
          const { title, content, excerpt, date, id } = post;

          return { id, title, content, excerpt, date, authorName, imageUrl };
        })
      );

      setPosts([...postsWithData]);
      setTotalPages(response.headers.get("X-WP-TotalPages"));

      return postsWithData;
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  // Function to load more posts
  const loadMorePosts = async () => {
    // Check if there are more pages to fetch
    if (page < totalPages) {
      // Increment the page number
      setPage(page + 1);
      const nextPosts = await fetchPosts({ page: page + 1, perPage: 4 });
      setPosts([...posts, ...nextPosts]);
    }
  };

  // Fetch function to fetch posts
  useEffect(() => {
    fetchPosts({ page, perPage: 4 });
  }, []);

  return (
    <Wrapper id='blog'>
      <Box px={[30, 30, 30, 30, 40, 40]} py={[18, 18, 18, 20, 24, 24]}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Our Blog Stories</h1>
            <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <SimpleGrid columns={[1, 1, 2, 3, 4, 4]} spacing={8}>
            {posts.map((post) => {
              const { title, excerpt, date, authorName, imageUrl, id } = post;
              return (
                <BlogBox
                  id={id}
                  title={title.rendered}
                  content={excerpt.rendered}
                  date={date}
                  tag='company'
                  author={authorName}
                  imageUrl={imageUrl}
                />
              );
            })}
          </SimpleGrid>
          {page < totalPages && (
            <Button onClick={loadMorePosts}>Load more</Button>
          )}
          <div className='row flexCenter'>
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title='Load More' action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </Box>
      {/* <Box bgColor='white' style={{ padding: "50px 0" }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>What They Say?</h1>
            <p className='font13'>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </Box> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
export default Blog;
