export const serviceItems = [
  {
    id: "structural-engineering",
    icon: "structural",
    title: "Structural Engineering",
    description:
      "Delivering robust and sustainable structural designs for a diverse range of projects, from commercial buildings to industrial facilities.",
  },
  {
    id: "mechanical-engineering",
    icon: "mechanical",
    title: "Mechanical Engineering",
    description:
      "Precision in motion. Our mechanical engineering expertise ensures efficiency, reliability, and optimal performance across various industries.",
  },
  {
    id: "electrical-electronics",
    icon: "electric",
    title: "Electrical & Electronics",
    description:
      "Powering progress. We design and implement state-of-the-art electrical and electronics solutions that meet the demands of the modern world.",
  },
  {
    id: "civil-engineering",
    icon: "civil",
    title: "Civil Engineering",
    description:
      "Building the future with a strong foundation. Our civil engineering services encompass infrastructure development, urban planning, and environmental sustainability.",
  },
  {
    id: "automation-control",
    icon: "automation",
    title: "Automation & Control Systems",
    description:
      "Streamlining operations through advanced automation and control systems that enhance productivity and reduce downtime.",
  },
];
