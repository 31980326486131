import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { trim, isEmpty } from "lodash";
import { HiOutlinePhoneIncoming } from "react-icons/hi";
import { useToast } from "@chakra-ui/react";

import styled from "styled-components";
import {
  Button,
  Heading,
  Box,
  Image,
  VStack,
  Text,
  Icon,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";

import {
  emailPattern,
  isRequired,
  minLength,
} from "../../utils/formValidators";
import ContactBanner from "../../assets/img/contact/contact-banner.jpeg";

import useScrollObserver from "../hooks/useScrollObserver";
import { SectionHeading } from "../../components/Elements/SharedComponents";

const StyledTextInput = ({
  id,
  name,
  placeholder,
  label,
  error,
  registerValidation,
  required = false,
  onBlur,
}) => {
  return (
    <FormControl isRequired={required} isInvalid={!!error} mb={8}>
      <FormLabel htmlFor={id} m={0} fontWeight='semibold'>
        {label}:
      </FormLabel>
      <Input
        type='text'
        id={id}
        name={name}
        placeholder={placeholder}
        {...registerValidation}
        variant='flushed'
        onBlur={onBlur}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

const StyledTextareaInput = ({
  id,
  name,
  placeholder,
  label,
  error,
  registerValidation,
  required = false,
  onBlur,
}) => {
  return (
    <FormControl isRequired={required} isInvalid={!!error} mb={8}>
      <FormLabel htmlFor={id} m={0} fontWeight='semibold'>
        {label}:
      </FormLabel>
      <Textarea
        id={id}
        name={name}
        placeholder={placeholder}
        {...registerValidation}
        variant='flushed'
        onBlur={onBlur}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

const Contact = () => {
  const sectionIds = ["contact-form-section"];
  const threshold = 0.2; // Example threshold value

  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Use the hook to track visible sections
  const visibleSections = useScrollObserver(sectionIds, threshold);
  const baseUrl = process.env.REACT_APP_WORDPRESS_BASE_API_ENDPOINT;
  const SAMPLE = `${baseUrl}/wp-json/contact-form-7/v1/contact-forms/31/feedback`;
  const SUBMIT_API = `https://api.ausk.vn/wp-json/contact-form-7/v1/contact-forms/25/feedback`;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm();

  const onSubmit = async (values) => {
    setIsLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("your-name", values.name);
    formDataToSend.append("your-email", values.email);
    formDataToSend.append("your-phone-number", values.phoneNumber);
    formDataToSend.append("your-subject", values.subject);
    formDataToSend.append("your-message", values.message);
    formDataToSend.append("_wpcf7_unit_tag", "wpcf7-f25-o1");

    try {
      const response = await fetch(`${SUBMIT_API}`, {
        method: "POST",
        body: formDataToSend,
      });
      const responseData = await response.json();
      setIsLoading(false);
      setIsSent(true);

      // Clear input values after successful submission
      reset();
    } catch (error) {
      setIsLoading(false);

      console.error("Error submitting form:", error);
    }
  };

  const toast = useToast();

  const handleOnBlur = () => clearErrors();

  useEffect(() => {
    if (isSent) {
      toast({
        position: "top",
        title: "Message sent!",
        description: "We will get back to you soon!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isSent, toast]);

  return (
    <Box id='contact' bgColor='#f6f6f7'>
      <Box
        id='contact-form-section'
        style={{
          opacity: visibleSections.includes("contact-form-section" ? 1 : 0),
          transition: "opacity 1s ease-in-out",
        }}
        p={16}
        height='100%'
        px={[30, 30, 30, 30, 40, 40]}>
        <SectionHeading
          sectionTitle={`Let's get in touch`}
          subDescription={
            <Text pr={[8, 16, 16, 16, 32, 32]} pl={[8, 16, 16, 16, 0, 0]}>
              Ready to embark on your next engineering venture? Contact us today
              for a consultation. Let's build a future together.
            </Text>
          }
        />
        <HStack spacing={24} justify='space-between'>
          <Box w={["100%", "100%", "100%", "70%", "50%", "50%"]}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} spacingX={8} mt={24}>
                <StyledTextInput
                  id='name'
                  label='Your Name'
                  required
                  placeholder='Enter your name'
                  registerValidation={register("name", {
                    validate: {
                      required: (value) => isRequired(value),
                      minLength: (value) => minLength(value, 3),
                    },
                  })}
                  error={errors.name}
                  onBlur={handleOnBlur}
                />
                <StyledTextInput
                  id='email'
                  label='Your Email'
                  placeholder='Enter your email'
                  required
                  registerValidation={register("email", {
                    validate: {
                      required: isRequired,
                      minLength: emailPattern,
                    },
                  })}
                  error={errors.email}
                  onBlur={handleOnBlur}
                />
                <StyledTextInput
                  id='phoneNumber'
                  label='Your Phone Number'
                  placeholder='Enter your phone number'
                />
                <StyledTextInput
                  id='subject'
                  label='Your Subject'
                  placeholder='Enter the subject'
                />
              </SimpleGrid>
              <StyledTextareaInput
                id='message'
                label='Your Message'
                required
                placeholder='Type your message here...'
                error={errors.message}
                registerValidation={register("message", {
                  validate: {
                    required: isRequired,
                    minLength: (value) => minLength(value, 20),
                  },
                })}
                onBlur={handleOnBlur}
              />
              <Box textAlign='right'>
                <Button
                  mt={4}
                  bgColor='orange.300'
                  isLoading={isLoading}
                  type='submit'>
                  Send Message
                </Button>
              </Box>
            </form>
          </Box>
          <Box
            w='30%'
            display={["none", "none", "none", "block", "block", "block"]}>
            <Image src={ContactBanner} mt='50px' />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default Contact;
