import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0.8;
`;

const Backdrop = ({ toggleSidebar }) => (
  <Wrapper className='darkBg' onClick={() => toggleSidebar(false)}></Wrapper>
);

export default Backdrop;
