import React from "react";
import { Button } from "@chakra-ui/react";

const MainButton = ({ text, onClick }) => (
  <Button
    onClick={onClick}
    height='48px'
    width='200px'
    fontWeight='bold'
    fontSize={20}
    position='relative'
    overflow='hidden'
    cursor='pointer'
    userSelect='none'
    touchAction='manipulation'
    color='white'
    bgColor='orange.400'
    borderColor='orange.400'
    borderWidth='3px'
    borderStyle='solid'
    _focus={{
      outline: "none",
    }}
    _hover={{
      "&:after": {
        transform: "skewY(9.3deg) scaleY(2)",
      },
      span: {
        color: "orange.500",
        zIndex: 1,
        transition: "color 0.5s ease-in-out",
      },
    }}
    _after={{
      content: '""',
      position: "absolute",
      bottom: "-50%",
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "orange.50",
      transformOrigin: "bottom center",
      transition: "transform 600ms cubic-bezier(0.48, 0, 0.12, 1)",
      transform: "skewY(9.3deg) scaleY(0)",
      overflow: "visible",
    }}>
    <span>{text}</span>
  </Button>
);

export default MainButton;
