import React, { useEffect, useRef, useState } from "react";

import { Box, Text, SimpleGrid } from "@chakra-ui/react";

// Components
import ClientSlider from "../../components/Elements/ClientSlider";

import { ServiceBoxItem } from "./ServiceBox";
import {
  SectionHeading,
  SectionInnerWrapper,
} from "../../components/Elements/SharedComponents";

import { serviceItems } from "./constants";

const Services = () => {
  const [sectionVisible, setSectionVisible] = useState(false);
  const [serviceBoxVisible, setServiceBoxVisible] = useState(false);

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSectionVisible(true);
          }
        });
      },
      { threshold: 0.15 }
    ); // Trigger when 10% of the section is visible

    observer.observe(sectionRef.current);

    return () => {
      observer.unobserve(sectionRef.current);
    };
  }, []);

  useEffect(() => {
    if (sectionVisible) {
      // Trigger service box visibility after section is visible
      setServiceBoxVisible(true);
    }
  }, [sectionVisible]);

  return (
    <Box mt='100px' pt={[18, 18, 18, 0, 0, 0]} bgColor='whiteAlpha.900'>
      {/* <Box px={[30, 30, 30, 30, 40, 40]} py={[18, 18, 18, 20, 24, 24]}>
      <ClientSlider />
    </Box> */}
      <SectionInnerWrapper extraStyles={{ bgColor: "whiteAlpha.900" }}>
        <Box
          width='100%'
          ref={sectionRef}
          opacity={sectionVisible ? 1 : 0}
          transition='opacity 1s ease-in-out'>
          <SectionHeading
            sectionTitle=' Our Solutions'
            subDescription={
              <Text pr={[8, 16, 16, 16, 32, 32]} pl={[8, 16, 16, 16, 0, 0]}>
                At AUSK, we are committed to offering comprehensive solutions
                spanning various stages of project development, from
                conceptualization to detailed design, and improvement
                consulting. With a robust team boasting exceptional technical
                prowess, we guarantee high-end quality delivery even for
                projects with tight schedules.
              </Text>
            }
          />
          <SimpleGrid columns={[1, 1, 2, 3, 3, 3]} spacing={16} mt={16}>
            {serviceItems.map((item, index) => (
              <ServiceBoxItem
                key={item.id}
                index={index}
                icon={item.icon}
                title={item.title}
                description={item.description}
                visible={serviceBoxVisible}
              />
            ))}
          </SimpleGrid>
        </Box>
      </SectionInnerWrapper>
    </Box>
  );
};

export default Services;
