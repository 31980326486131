import React from "react";
import { HStack, Image, Text } from "@chakra-ui/react";
import { PhoneIcon, EmailIcon, TimeIcon } from "@chakra-ui/icons";

import TextWithIcon from "../Elements/TextWithIcon";
import engFlagSrc from "../../assets/img/eng-flag.png";

const extraWrapperStyles = { display: ["none", "none", "flex", "flex"] };
const SubNavBar = ({ y, x }) => (
  <HStack
    height={30}
    width='100%'
    position='fixed'
    top='0'
    left='0'
    zIndex='999'
    bgColor='#5a5b5f'
    paddingX={30}
    justifyContent={[
      "flex-end",
      "flex-end",
      "space-between",
      "space-between",
      "space-between",
    ]}
    visibility={y > 100 ? "visible" : "hidden"}>
    <HStack spacing={6} height='100%' mx={5}>
      <HStack spacing={2} display={["none", "none", "none", "flex"]}>
        <Text as='b' textColor='orange.300'>
          We are waiting for you:
        </Text>
      </HStack>
      <TextWithIcon
        icon={TimeIcon}
        iconColor='orange.300'
        textColor='whiteAlpha.900'
        text='Mon - Fri: 10am - 5pm'
        iconSize={4}
        extraWrapperStyles={extraWrapperStyles}
      />
      <TextWithIcon
        icon={EmailIcon}
        iconColor='orange.300'
        textColor='whiteAlpha.900'
        text='info@ausk.vn'
        iconSize={4}
        extraWrapperStyles={extraWrapperStyles}
      />
      {/* <TextWithIcon
        icon={PhoneIcon}
        iconColor='orange.300'
        textColor='whiteAlpha.900'
         text='+61 426 740 303'
        iconSize={4}
        extraWrapperStyles={extraWrapperStyles}
      /> */}
    </HStack>
    <HStack alignItems='center' mr={[0, 0, 0, 0, 5, 5]}>
      <Image w={4} h={4} src={engFlagSrc} />
      <Text textColor='whiteAlpha.900'>EN</Text>
    </HStack>
  </HStack>
);

export default SubNavBar;
