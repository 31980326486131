import { useState, useEffect, useRef } from "react";

const useScrollObserver = (ids, threshold) => {
  const [visibleSections, setVisibleSections] = useState([]);
  const sectionRefs = useRef({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.id;
          if (entry.isIntersecting) {
            setVisibleSections((prevVisibleSections) => {
              if (!prevVisibleSections.includes(id)) {
                return [...prevVisibleSections, id];
              }
              return prevVisibleSections;
            });
          }
        });
      },
      { threshold: threshold }
    );

    ids.forEach((id) => {
      const sectionElement = document.getElementById(id);
      if (sectionElement) {
        sectionRefs.current[id] = sectionElement;
        observer.observe(sectionElement);
      }
    });

    return () => {
      ids.forEach((id) => {
        const sectionElement = sectionRefs.current[id];
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, [ids, threshold]);

  return visibleSections;
};

export default useScrollObserver;
