import React from "react";
import { Image, HStack } from "@chakra-ui/react";

import LeftBanner from "../../assets/img/banner/left-banner-light-orange.png";
import RightBanner from "../../assets/img/banner/right-banner-light-orange.png";

const HeaderBanner = ({ showAnimation }) => {
  return (
    <HStack
      spacing={0}
      overflow='hidden'
      w='100%'
      justifyContent='space-between'>
      <Image
        src={LeftBanner}
        alt='banner'
        height='700px'
        w='60%'
        transform={showAnimation ? "translateX(0%)" : "translateX(-100%)"} // Initially positioned off-screen to the left
        transition='transform 1s ease-out'
        zIndex={2}
        display={["none", "none", "none", "none", "block", "block"]}
      />

      <Image
        position={[
          "relative",
          "relative",
          "relative",
          "relative",
          "absolute",
          "absolute",
        ]}
        zIndex={1}
        src={RightBanner}
        alt='banner'
        height='700px'
        width={["100%", "100%", "100%", "100%", "65%", "65%"]}
        top={showAnimation ? 0 : "-100%"}
        right={0}
        transition={[
          "top 1s ease-out",
          "top 1s ease-out",
          "top 1s ease-out",
          "top 1s ease-out",
          "top 1s ease-out",
          "top 1s ease-out",
        ]}
        display={["flex", "flex", "flex", "flex", "flex", "flex"]}
      />
    </HStack>
  );
};

export default HeaderBanner;
