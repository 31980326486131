import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import { Heading, Box, Badge, HStack, Text } from "@chakra-ui/react";

// Assets

import { CompanyLogo } from "../components/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Box
      px={[30, 30, 30, 30, 40, 40]}
      bgColor='#292a2b'
      py={[2, 2, 2, 2, 2, 2]}>
      <HStack justifyContent='space-between'>
        <Link
          className='flexCenter animate pointer'
          to='home'
          smooth={true}
          offset={-80}>
          <CompanyLogo />
        </Link>
        <Text color='white'>
          © {getCurrentYear()} - <span>AUSK</span> All Right Reserved
        </Text>

        <Link
          className='whiteColor animate pointer font13'
          to='home'
          smooth={true}
          offset={-80}>
          <Text color='white' whiteSpace='nowrap'>
            {" "}
            Back to top
          </Text>
        </Link>
      </HStack>
    </Box>
  );
}
