import React from "react";
import { HStack, VStack, Box } from "@chakra-ui/react";

import { CloseButton } from "@chakra-ui/react";
import LinkItems from "./LinkItems";

import { CompanyLogo } from "../Logo";

const linkItemOnHoverStyles = {
  background: "#414245",
  opacity: 0.8,
  width: "100%",
};
const SideBarHeader = ({ toggleSidebar }) => (
  <HStack alignItems='flex-start' justifyContent='space-between'>
    <CompanyLogo extraStyles={{ ml: 12 }} />
    <CloseButton
      className='animate'
      color='whiteAlpha.800'
      size='lg'
      cursor='pointer'
      onClick={(state) => toggleSidebar(!state)}
    />
  </HStack>
);

const SidebarItems = ({ toggleSidebar }) => (
  <VStack
    as='u'
    mt={16}
    spacing={4}
    textAlign='center'
    listStyleType='none'
    alignItems='center'
    textDecoration='none'>
    <LinkItems
      extraOnClick={(state) => toggleSidebar(!state)}
      showIcon
      extraTextStyles={{ pl: 12, pb: 1, _hover: linkItemOnHoverStyles }}
    />
  </VStack>
);

const Sidebar = ({ sidebarOpen, toggleSidebar }) => (
  <Box
    width='400px'
    height='100vh'
    position='fixed'
    top={0}
    py='30px'
    zIndex={9999}
    right={sidebarOpen ? 0 : "-400px"}
    className='animate'
    sidebarOpen={sidebarOpen}
    bgColor='#292a2b'>
    <SideBarHeader toggleSidebar={toggleSidebar} />
    <SidebarItems toggleSidebar={toggleSidebar} />
  </Box>
);

export default Sidebar;
