import React from "react";
import styled from "styled-components";

import { Heading, Box, Badge } from "@chakra-ui/react";

export const SectionHeading = ({
  sectionTitle,
  subDescription,
  statusInfo,
  extraStyles,
}) => (
  <Box
    textAlign={["center", "center", "center", "center", "left", "left"]}
    {...extraStyles}>
    <Heading fontSize={[40, 40, 40, 42, 42, 42]} fontWeight='extrabold' mb={4}>
      {sectionTitle}
    </Heading>
    {subDescription}
    {statusInfo && (
      <Badge colorScheme='green' mt={4} fontSize={[18]}>
        {statusInfo}
      </Badge>
    )}
  </Box>
);

export const SectionInnerWrapper = ({ children, extraStyles }) => (
  <Box
    px={[30, 30, 30, 30, 40, 40]}
    pt={[18, 18, 18, 20, 20, 20]}
    pb={[18, 18, 18, 20, 24, 24]}
    {...extraStyles}>
    {children}
  </Box>
);
