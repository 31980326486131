import React, { useState, useCallback, useEffect } from "react";
import { Button, Heading, Box, Image } from "@chakra-ui/react";

// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../sections/Header";
import Services from "../sections/Services";
import Projects from "../sections/Projects";
import Blog from "../sections/Blog";
import About from "../sections/About";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";

const baseUrl = process.env.REACT_APP_WORDPRESS_BASE_API_ENDPOINT;
const GET_POSTS_API = `${baseUrl}/wp-json/wp/v2/posts`;
const SUBMIT_API = `${baseUrl}//wp-json/contact-form-7/v1/contact-forms`;

const Landing = () => {
  const [posts, setPosts] = useState([]);

  const submitForm = async () => {
    await fetch(SUBMIT_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        your_name: "John Doe",
        your_email: "fsfsd",
      }),
    });
  };

  // const fetchPosts = useCallback(async () => {
  //   const response = await fetch(GET_POSTS_API);
  //   const data = await response.json();
  //   setPosts(data);
  // }, []);

  // useEffect(() => {
  //   fetchPosts();
  // }, [fetchPosts]);

  // if (!posts) {
  //   return null;
  // }
  // console.log({ posts });

  return (
    <div>
      <Box id='home'>
        <TopNavbar />
        <Header />
      </Box>
      <Box id='services' w='100%'>
        <Services />
      </Box>
      <Box id='projects' w='100%'>
        <Projects />
      </Box>
      <Box id='about' w='100%'>
        <About />
      </Box>
      <Box id='contact' w='100%'>
        <Contact />
      </Box>
      <Footer />
    </div>
  );
};
export default Landing;
