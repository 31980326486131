import React, { useState, useEffect, useRef, Card } from "react";
import styled from "styled-components";
import { Link as ReactScrollLink } from "react-scroll";
import Scrollspy from "react-scrollspy";
import { FiLinkedin } from "react-icons/fi";

import { FaLinkedin } from "react-icons/fa";

import {
  Box,
  Image,
  VStack,
  Text,
  Icon,
  SimpleGrid,
  Link,
  AvatarBadge,
  HStack,
} from "@chakra-ui/react";
// Components
import {
  SectionHeading,
  SectionInnerWrapper,
} from "../../components/Elements/SharedComponents";

import { boardTeamMembers, teamMembers } from "./constants";

const AvatarWithInfo = ({
  src,
  memberInfo,
  isVisible,
  h = [200, 180, 160, 160, 180, 180],
  w = [200, 180, 160, 160, 180, 180],
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      cursor='pointer'
      style={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
      }}>
      <Box
        borderRadius='full'
        overflow='hidden'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position='relative'
        h={h} // Adjust as needed
        w={w} // Adjust as needed
        bgImage={`url(${src})`}
        bgSize='cover'
        bgPosition='center'>
        {isHovered && (
          <Box
            position='absolute'
            bottom={0}
            left={0}
            w='100%'
            textAlign='center'
            bg='gray.300'
            opacity='0.7'
            p={2}>
            <Link href={memberInfo.linkedinUrl} isExternal>
              <Icon as={FiLinkedin} color='blue.500' boxSize='1.5em' />
            </Link>
          </Box>
        )}
      </Box>
      <Box textAlign='center' mt={2}>
        <Text fontWeight='bold'>{memberInfo.name}</Text>
        <Text fontSize='sm' color='gray.500'>
          {memberInfo.position}
        </Text>
      </Box>
    </Box>
  );
};

const MeetOutTeamsDescription = ({ activated }) => (
  <SectionHeading
    sectionTitle='Meet Our Teams'
    subDescription={
      <Text pr={[8, 16, 16, 16, 0, 0]} pl={[8, 16, 16, 16, 0, 0]}>
        At AUSK, we are committed to offering comprehensive solutions spanning
        various stages of project development, from conceptualization to
        detailed design, and improvement consulting. With a robust team boasting
        exceptional technical prowess, we guarantee high-end quality delivery
        even for projects with tight schedules.
      </Text>
    }
    style={{
      opacity: activated ? 1 : 0,
      transition: "opacity 1s ease-in-out",
    }}
  />
);

const MeetOutTeams = ({ id, ref, activated }) => {
  return (
    <>
      <Box
        id={id}
        ref={ref}
        width='100%'
        display='flex'
        flexDirection={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        justifyContent={[
          "center",
          "center",
          "center",
          "center",
          "space-between",
          "space-between",
        ]}
        alignItems={[
          "center",
          "center",
          "center",
          "center",
          "flex-start",
          "flex-start",
        ]}
        style={{
          opacity: activated ? 1 : 0,
          transition: "opacity 0.1s ease-in-out",
        }}>
        <Box>
          <SimpleGrid
            columns={[1, 2, 2, 2, 2, 2]}
            spacingX={[12, 12, 12, 12, 14, 16]}
            spacingY={[12, 12, 12, 12, 8, 8]}
            mt={[12, 12, 12, 12, 12, 12]}>
            {boardTeamMembers.map((memberInfo) => (
              <AvatarWithInfo
                src={memberInfo.src}
                memberInfo={memberInfo}
                isVisible={activated}
              />
            ))}
          </SimpleGrid>
        </Box>
        <Box
          width={["100%", "100%", "100%", "100%", "60%", "60%"]}
          mt={[12, 12, 12, 12, 12, 12]}
          pl={[0, 0, 0, 0, 12, 0]}>
          <MeetOutTeamsDescription activated={activated} />
        </Box>
      </Box>
      <SimpleGrid
        columns={[1, 4, 4, 4, 4, 4]}
        spacingX={[12, 12, 12, 12, 14, 16]}
        spacingY={[12, 12, 12, 12, 8, 8]}
        mt={[12, 12, 12, 12, 12, 12]}>
        {teamMembers.map((memberInfo) => (
          <AvatarWithInfo
            src={memberInfo.src}
            memberInfo={memberInfo}
            isVisible={activated}
            h={[140, 110, 140, 140, 140, 140]}
            w={[140, 110, 140, 140, 140, 140]}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default MeetOutTeams;
