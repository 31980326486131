import React from "react";
import { Link } from "react-scroll";
import { Icon, Text } from "@chakra-ui/react";
import {
  GoHome,
  GoLog,
  GoProjectRoadmap,
  GoBook,
  GoPeople,
} from "react-icons/go";

import { HiOutlinePhoneIncoming } from "react-icons/hi";

import { isUndefined, noop } from "lodash";
import styled from "@emotion/styled";

import MainButton from "../Buttons/MainButton";

const StyledLink = styled(Link)`
  width: 100%;
  text-align: left;
`;

const LinkItem = ({
  text,
  id,
  icon,
  onClick,
  showIcon,
  offset,
  extraTextStyles = {},
}) => (
  <Text
    width='100%'
    as='li'
    fontWeight='bold'
    cursor='pointer'
    fontSize={18}
    display='flex'
    alignItems='center'
    justifyContent='flex-start'
    color='whiteAlpha.900'
    whiteSpace='nowrap'
    _hover={{ color: "whiteAlpha.800" }}
    {...extraTextStyles}>
    {icon ? <Icon as={icon} color='orange.300' w={5} h={5} /> : null}
    <StyledLink
      activeClass={showIcon ? "showIconActive" : "active"}
      style={{ padding: "10px 15px" }}
      to={id}
      spy={true}
      smooth={true}
      offset={offset}
      onClick={!isUndefined(onClick) ? onClick : noop}>
      {text}
    </StyledLink>
  </Text>
);

const LinkItems = ({
  showIcon,
  extraOnClick = undefined,
  extraTextStyles = {},
}) => (
  <>
    <LinkItem
      id='home'
      text='Home'
      onClick={extraOnClick}
      icon={showIcon ? GoHome : undefined}
      extraTextStyles={extraTextStyles}
      showIcon={showIcon}
      offset={-200}
    />
    <LinkItem
      id='services'
      text='Solutions'
      onClick={extraOnClick}
      icon={showIcon ? GoProjectRoadmap : undefined}
      showIcon={showIcon}
      extraTextStyles={extraTextStyles}
      offset={-200}
    />
    <LinkItem
      id='projects'
      text='Projects'
      onClick={extraOnClick}
      icon={showIcon ? GoLog : undefined}
      showIcon={showIcon}
      extraTextStyles={extraTextStyles}
      offset={-200}
    />
    <LinkItem
      id='about'
      text='About'
      onClick={extraOnClick}
      icon={showIcon ? GoPeople : undefined}
      showIcon={showIcon}
      extraTextStyles={extraTextStyles}
      offset={-80}
    />
    {/* <LinkItem
      id='blog'
      text='Blog'
      onClick={extraOnClick}
      icon={showIcon ? GoBook : undefined}
      showIcon={showIcon}
      extraTextStyles={extraTextStyles}
    /> */}

    <Link to='contact' smooth={true} duration={1000} onClick={extraOnClick}>
      <MainButton text='Get in touch' onClick={() => {}} />
    </Link>

    {/* <LinkItem
      id='contact'
      text='Get in touch'
      onClick={extraOnClick}
      icon={showIcon ? HiOutlinePhoneIncoming : undefined}
      showIcon={showIcon}
      extraTextStyles={extraTextStyles}
    /> */}
  </>
);

export default LinkItems;
