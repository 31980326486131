import { Image, Box } from "@chakra-ui/react";
import styled from "styled-components";

// Define a styled component that wraps the Chakra UI Image component
const StyledImageWrapper = styled(Box)`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const CustomImage = ({
  src,
  alt,
  frameWidth,
  frameMargin,
  extraStyles,
  frameHeight, // Add height prop
}) => (
  <Box
    width={frameWidth}
    height={frameHeight} // Add height prop
    margin={frameMargin}
    className='img-frame'
    overflow='hidden'
    borderRadius='1rem'
    boxShadow='0 2px 15px rgba(0, 0, 0, 0.3)'
    _hover={{
      boxShadow: "0 2px 15px rgba(0, 0, 0, 0.5)",
    }}
    {...extraStyles}>
    <Image
      src={src}
      alt={alt}
      width='100%' // Ensure the image fills the container width
      height='100%' // Ensure the image fills the container height
      objectFit='cover' // Scale and crop the image to fill the container
    />
  </Box>
);

export default CustomImage;
