import React from "react";
import styled from "styled-components";
import { Heading, Box, Image, VStack, Text, Stack } from "@chakra-ui/react";

import AutomationIcon from "../../assets/img/services/automation.png";
import StructuralIcon from "../../assets/img/services/structural.png";
import CivilIcon from "../../assets/img/services/civil.png";
import MechanicalIcon from "../../assets/img/services/mechanical-2.png";
import ElectricIcon from "../../assets/img/services/electric-2.png";

const ServiceBoxWrapper = ({ children }) => (
  <Box textAlign={["center", "center", "left", "left", "left", "left"]}>
    {children}
  </Box>
);

const ServiceIcon = ({ src }) => <Image src={src} boxSize='50px' />;

const getServiceIcon = (icon) => {
  switch (icon) {
    case "structural":
      return StructuralIcon;
    case "civil":
      return CivilIcon;
    case "mechanical":
      return MechanicalIcon;
    case "automation":
      return AutomationIcon;
    case "electric":
      return ElectricIcon;
    default:
      return AutomationIcon;
  }
};

const ServiceBoxItem = ({ key, index, icon, title, description, visible }) => (
  <VStack
    key={key}
    opacity={visible ? 1 : 0}
    transition={`opacity 0.5s ease ${(index + 1) * 0.12}s`}
    transform={visible ? "translateY(0)" : "translateY(100px)"}
    alignItems={[
      "center",
      "center",
      "flex-start",
      "flex-start",
      "flex-start",
      "flex-start",
    ]}>
    <Stack
      flexDirection={["column", "column", "row", "row", "row", "row"]}
      alignItems='center'>
      <ServiceIcon src={getServiceIcon(icon)} />
      <Heading as='h4' size='md' whiteSpace='nowrap' ml={[0, 0, 2, 2, 2, 2]}>
        {title}
      </Heading>
    </Stack>
    <Text px={[16, 16, 0, 0, 0, 0]} fontWeight='light'>
      {description}
    </Text>
  </VStack>
);

export { ServiceBoxItem, ServiceIcon, ServiceBoxWrapper };
