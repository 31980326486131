import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

import RightContent from "./RightContent";
import HeaderBanner from "./HeaderBanner";
import UnderConstruction from "./UnderConstruction";

const HomeSection = () => {
  const isUnderConstruction = false;
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setShowAnimation(true);
  }, []);

  return (
    <>
      <Box as='section' width='100%' position='relative' top='100px'>
        {isUnderConstruction ? <UnderConstruction /> : null}
        <HeaderBanner showAnimation={showAnimation} />
        {!isUnderConstruction ? (
          <RightContent showAnimation={showAnimation} />
        ) : null}
      </Box>
    </>
  );
};
export default HomeSection;
