import React, { StrictMode } from "react";
import { Helmet } from "react-helmet";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Screens
import Landing from "./screens/Landing.jsx";
import BlogPost from "./screens/BlogPost.jsx";

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/posts/:postId' element={<BlogPost />} />
          <Route path='/' element={<Landing />} />
        </Routes>
      </Router>
    </>
  );
}
