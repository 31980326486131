import React from "react";
import styled from "styled-components";

import {
  Button,
  Heading,
  Box,
  Image,
  VStack,
  Text,
  Icon,
  SimpleGrid,
} from "@chakra-ui/react";

// Components
import CustomImage from "../../components/CustomImage";

import {
  SectionHeading,
  SectionInnerWrapper,
} from "../../components/Elements/SharedComponents";
// Assets

import AddImage1 from "../../assets/img/add/sample_1.png";
import AddImage2 from "../../assets/img/add/sample_4.png";
import AddImage3 from "../../assets/img/add/sample_2.png";
import AddImage4 from "../../assets/img/add/sample_3.png";

import useScrollObserver from "../hooks/useScrollObserver";

const ProjectImagesSection = ({ id, isVisible }) => (
  <Box
    id={id}
    width={["80%", "80%", "80%", "50%", "50%", "50%"]} // Adjust width based on screen size
    position={[
      "relative",
      "relative",
      "relative",
      "absolute",
      "absolute",
      "absolute",
    ]} // Adjust position based on screen size
    top={[0, 0, 0, -75, -75, -70]} // Adjust top position based on screen size
    right={[0, 0, 0, 0, 0, 0, 0]} // Right position only for medium and larger screens
    transform={["scale(-0.9)", "none", "none", "none", "none", "none"]}
    style={{
      opacity: isVisible ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    }}>
    <Box display='flex'>
      <CustomImage
        src={AddImage1}
        alt='office'
        frameWidth='285px'
        frameHeight='406px'
        frameMargin='0 6% 10px 6%'
      />
      <CustomImage
        src={AddImage2}
        alt='office'
        frameWidth='197px'
        frameHeight='333px'
        frameMargin='0 5% 10px 5%'
      />
    </Box>
    <Box display='flex'>
      <CustomImage
        src={AddImage3}
        alt='office'
        frameWidth='112px'
        frameHeight='115px'
        frameMargin='0 0 0 auto'
      />
      <CustomImage
        src={AddImage4}
        alt='office'
        frameWidth='197px'
        frameHeight='224px'
        frameMargin='0 10% auto 5%'
      />
    </Box>
  </Box>
);

const ProjectSectionOverview = ({ id, isVisible }) => (
  <Box
    id={id}
    mt={[20, 20, 20, 0, 0, 0]}
    width={["100%", "100%", "100%", "50%", "50%", "50%"]}
    order={{
      base: "2",
      md: "2",
      lg: "2",
      xl: "initial",
      "2xl": "initial",
      "3xl": "initial",
    }}
    textAlign={{
      base: "center",
      md: "center",
      lg: "center",
      xl: "left",
      "2xl": "left",
      "3xl": "left",
    }}
    style={{
      opacity: isVisible ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    }}>
    <SectionHeading
      statusInfo='Stay Tuned: Updating'
      sectionTitle='Our Showcase'
      subDescription={
        <Text pr={[8, 16, 16, 16, 32, 32]} pl={[8, 16, 16, 16, 0, 0]}>
          Experience our expertise in crafting resilient and sustainable
          structural designs tailored to a wide array of projects, from towering
          commercial complexes to industrial powerhouses. Dive into our
          portfolio and witness the fusion of creativity, precision, and
          functionality in every detail.
        </Text>
      }
    />
  </Box>
);

const Projects = () => {
  const sectionIds = ["overview-section", "project-image-section"];
  const threshold = 0.2; // Example threshold value

  // Use the hook to track visible sections
  const visibleSections = useScrollObserver(sectionIds, threshold);

  return (
    <Box px={[30, 30, 30, 30, 40, 40]} pb={[18, 18, 18, 20, 24, 24]}>
      <Box
        // display={["block", "block", "block", "flex", "flex", "flex"]}
        display='flex'
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
          "row",
        ]}
        position='relative'
        justifyContent='space-between' // Adjust as needed
        alignItems='center' // Adjust as needed
        m={["0", "0", "0", "54px 0", "54px 0", "54px 0"]} // Responsive margin
        py={[8, 8, 8, 28, 28, 28]} // Responsive padding
      >
        <ProjectSectionOverview
          id='overview-section'
          isVisible={visibleSections.includes("overview-section")}
        />
        <ProjectImagesSection
          id='project-image-section'
          isVisible={visibleSections.includes("project-image-section")}
        />
      </Box>
    </Box>
  );
};

export default Projects;
