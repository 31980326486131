import React, { useState, useEffect, useRef, Card } from "react";
import styled from "styled-components";
import { Link as ReactScrollLink } from "react-scroll";
import Scrollspy from "react-scrollspy";

import { FaLinkedin } from "react-icons/fa";

import {
  Box,
  Image,
  VStack,
  Text,
  Icon,
  SimpleGrid,
  Link,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
// Components
import {
  SectionHeading,
  SectionInnerWrapper,
} from "../../components/Elements/SharedComponents";

import { companyValues } from "./constants";

import InnovationIcon from "../../assets/img/about/innovation-2.png";
import IntegrityIcon from "../../assets/img/about/integrity.png";
import ExcellenceIcon from "../../assets/img/about/excellence.png";
import QualityIcon from "../../assets/img/about/quality.png";
import GrowthIcon from "../../assets/img/about/growth.png";
import EmpowerIcon from "../../assets/img/about/empower.png";

const getValueMissionIcon = (icon) => {
  switch (icon) {
    case "innovation":
      return InnovationIcon;
    case "excellence":
      return ExcellenceIcon;
    case "integrity":
      return IntegrityIcon;
    case "empower":
      return EmpowerIcon;
    case "drive-innovation":
      return InnovationIcon;
    case "quality":
      return QualityIcon;
    case "growth":
      return GrowthIcon;
    default:
      return InnovationIcon;
  }
};

const InfoCardItem = ({ title, description, icon, activated, index }) => {
  return (
    <Box
      boxShadow='0 4px 6px rgba(0, 0, 0, 0.1)'
      bgColor='orange.50'
      borderRadius='2xl'
      p={4}
      textAlign='center'
      w={["300px", "300px", "250px", "300px", "250px", "300px"]}
      height={["400px", "400px", "420px", "420px", "420px", "400px"]}
      opacity={activated ? 1 : 0}
      transition={`opacity 0.5s ease ${
        (index + 1) * 0.15
      }s, border-color 1s ease-out`}
      transform={activated ? "translateY(0)" : "translateY(100px)"}
      borderColor={activated ? "transparent" : "orange"}
      borderStyle='solid'
      borderWidth='2px'
      _hover={{
        border: "2px solid orange",
        transform: "scale(1.05)",
        transition: "transform 0.3s ease",
      }}>
      <VStack mb={4} spacing={8} justify='space-between' height='100%'>
        <Image src={getValueMissionIcon(icon)} boxSize='100px' mt={10} />
        <VStack alignItems='center' mb={4}>
          <Text fontWeight='bold' fontSize='2xl'>
            {title}
          </Text>
          <Text>{description}</Text>
        </VStack>
      </VStack>
    </Box>
  );
};

const CardSections = ({ id, ref, activated, sectionTitle, data }) => {
  return (
    <Box mb={16}>
      <Box>
        <Box position='relative' padding='10' mb={8}>
          <Divider />
          <AbsoluteCenter bg='white' px='4'>
            <SectionHeading
              sectionTitle={sectionTitle}
              extraStyles={{ p: 8 }}
              style={{
                opacity: activated ? 1 : 0,
                transition: "opacity 0.2s ease-in-out",
              }}
            />
          </AbsoluteCenter>
        </Box>
        <SimpleGrid
          id={id}
          columns={[1, 1, 3, 3, 3, 3]}
          spacing={[6, 6, 6, 6, 6, 6]}
          justifyItems={[
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
          ]}>
          {data.map((value, index) => (
            <InfoCardItem
              id={`values-section-${value.id}`}
              index={index}
              activated={activated}
              key={value.id}
              title={value.title}
              description={value.description}
              icon={value.icon}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default CardSections;
