import React, { useEffect, useState } from "react";
import { HStack } from "@chakra-ui/react";

// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";

// Assets

import SubNavBar from "./SubNavBar";
import NavRightItems from "./NavRightItems";
import NavLogo from "./NavLogo";

const TopNavbar = () => {
  const [y, setY] = useState(window.scrollY);
  const [x, setX] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    window.addEventListener("scroll", () => setX(window.scrollX));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
      window.removeEventListener("scroll", () => setX(window.scrollX));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && (
        <Backdrop toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
      )}
      <SubNavBar y={y} />
      <HStack
        justifyContent='space-between'
        width='100%'
        position='fixed'
        top='0'
        left='0'
        zIndex='999'
        bgColor='#292a2b'
        className='animate'
        paddingX={30}
        mt={y > 100 ? 30 : 0}
        style={{
          height: y > 100 ? "80px" : "100px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)", // Border bottom with reduced opacity
          boxShadow: y > 100 ? "0px 4px 8px rgba(0, 0, 0, 0.4)" : "none", // Shadow effect with increased darkness
        }}
        opacity={y > 100 ? 0.85 : 1}>
        <NavLogo />
        <NavRightItems
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </HStack>
    </>
  );
};

export default TopNavbar;
