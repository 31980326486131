import React from "react";
import { HStack, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import LinkItems from "./LinkItems";

const NavRightItems = ({ sidebarOpen, toggleSidebar }) => (
  <HStack>
    <IconButton
      icon={<HamburgerIcon _hover={{ color: "whiteAlpha.700" }} />}
      onClick={() => toggleSidebar(!sidebarOpen)}
      variant='ghost'
      color='whiteAlpha.900'
      fontSize={40}
      display={["block", "block", "block", "block", "none", "none"]}
      _hover={{ background: "transparent" }}
    />
    <HStack
      as='u'
      listStyleType='none'
      alignItems='center'
      textDecoration='none'
      display={["none", "none", "none", "none", "flex", "flex"]}>
      <LinkItems />
    </HStack>
  </HStack>
);
export default NavRightItems;
