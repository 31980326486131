import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import "./style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";

const theme = extendTheme({
  fonts: {
    heading: `'IBMPlexSerif', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// ReactDOM.render(
//   <>
//     <React.StrictMode>
//       <ChakraProvider theme={theme}>
//         <App />
//       </ChakraProvider>
//     </React.StrictMode>
//   </>,
//   document.getElementById("root")
// );

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
